import PropTypes from 'prop-types';
import React from 'react';
import Image from 'next/image';

import { VIP_CARD_BACK } from '@common/images';

export function StaticCard({ image }) {
  return <Image src={image.src}
alt={image.alt}
layout="fill" />;
}

StaticCard.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
};

StaticCard.defaultProps = {
  image: {
    src: VIP_CARD_BACK,
    alt: 'VIP-Karte',
  },
};

export default StaticCard;
